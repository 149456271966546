export const AUDIENCEBUILDER_API_URL = {
  ID: 'Id',
  COUNTRIES: 'countries',
  AUDIENCES: 'audience',
  ATTRIBUTES: 'attributes',
  MANAGED_ATTRIBUTES: 'managedAttributes',
  LOCATION_ATTRIBUTE: 'locationAttributes',
  CUSTOM_RANGED_ATTRIBUTE: 'customRangedAttributes',
  MULTIVALUES: 'multivalues',
  ACTIVATEACTION: 'attribute/activateAction',
  NOVATIQATTRIBUTE: 'novatiqAttribute',
  AUDIENCE_DETAILS: 'audiencedetails',
  ATTRIBUTENAME: 'name',
  AUDIENCE_EXPRESSION: 'segment-expressions',
  AUDIENCE_PRICE: 'audience-price',
  TYPE: 'pricingFormat',
  FORMAT: 'format',
  ISSTANDARD: 'isStandard',
  UNKNOWN_PRESET: 'unknown-presets',
  ONBOARD_UNKNOWN_PRESET: 'onboard-multivalue-presets',
  DEVICE_CLASSIFICATION_SETTINGS: 'geography-settings'
};
